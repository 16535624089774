import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { baseUrl, deleteProjectUrl } from '../../util/shared';
import { deleteRequest } from '../../util/api';
import { fetchProjects, fetchProjectDataFile } from '../../util/stateChanges';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '../../state';

const Project = ({project}) => {
  const navigate = useNavigate();
  const url = deleteProjectUrl + project.projectData.projectId
  const modal = useSelector(state=>state.global.modal)
  const dispatch = useDispatch()
  function popover(){
    dispatch(setModal({message:"project",loading:true, id:project.projectData.projectId}))
  }

  useEffect(()=>{
    if(modal && Number(modal.id)===Number(project.projectData.projectId)){
      if(modal.loading===false){
        if(modal.doAction ){
          deleteProject()
        }
        dispatch(setModal({}))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[modal])
  function deleteProject(){
    deleteRequest(url).then(response=>{
      fetchProjects(dispatch)
    })
  }
  async function getDownloadFile() {
    let resp = await fetchProjectDataFile(project.projectData.projectId)
    const disposition = resp.headers.get("content-disposition")
    const filename = disposition.match(/filename=(.+)/)[1]
    let fileBlob = await resp.blob()

    const url = window.URL.createObjectURL(fileBlob)
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    a.remove()
  }

 
  return (
   

       <tr onClick={e=>{
         navigate('/viewproject/'+project.projectData.projectId)}} className="flex w-full bg-gray-700 hover:bg-gray-800 font-medium">
            <td className="px-4 py-6 w-1/4 cursor-default">{project.projectData.projectName}</td>
            <td className="px-4 py-6 w-1/4 cursor-default">{project.projectData.projectId}</td>
            <td className="px-4 py-6 w-1/4 cursor-default">{project.projectData.customerId}</td>
            <td className="px-4 py-6 w-1/4 cursor-default">{project.projectData.projectCategoryName}</td>
            <td className="px-4 py-6 w-1/4 cursor-default">{project.projectData.hardware}</td>
            <td className="px-4 py-4 w-1/4">
              <div className="flex-col lg:flex-row lg:space-x-2 items-center space-y-2 lg:space-y-0">
                <button onClick={e=>{
                  e.stopPropagation()
                  navigate('/updateproject/'+project.projectData.projectId)}}
                  className="items-center px-2 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                  </svg>
                </button>
               
                <button onClick={e=>{
                  e.stopPropagation()
                  popover()}}
                  className="items-center px-2 py-2 text-white bg-red-500 rounded-md hover:bg-red-600 focus:outline-none z-[1000]">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
                </button>

                <button onClick={e=>{
                  e.stopPropagation()
                  getDownloadFile()}}
                  className="items-center px-2 py-2 text-white bg-green-500 rounded-md hover:bg-green-600 focus:outline-none z-[1000]">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="white" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <g data-name="15.Arrow Down"><path d="M12 24a12 12 0 1 1 12-12 12.013 12.013 0 0 1-12 12zm0-22a10 10 0 1 0 10 10A10.011 10.011 0 0 0 12 2z"/><path d="m12 18.414-4.707-4.707 1.414-1.414L12 15.586l3.293-3.293 1.414 1.414L12 18.414z"/><path d="M11 6h2v11h-2z"/></g>
                  </svg>
                </button>
              </div>
            </td>
            
         </tr>
    
  )
}


export default Project