import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { putMethod } from '../../util/shared';
import ErrorComponent from '../shared/error';
import Label from '../shared/label';
import Input from '../shared/input';
import Button from '../shared/button';
import { apiRequest } from '../../util/api';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomers } from '../../util/stateChanges';

const UpdateCustomer = () => {
  const {id} = useParams()
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [displayError, display] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Unexpected Error!")
  const customer = useSelector(state=>state.global.customers).find(x=>Number(x.customerId)===Number(id))
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const location = useLocation();
  function updateCustomer(e){
      e.preventDefault();        
      const url = 'updatecustomer/'+id;
      const body = JSON.stringify({
        name:name,
        email: email,
        password: password,
      })
      apiRequest(url,putMethod,body).then((data) => {
              if(data !== null){
                fetchCustomers(dispatch)
                navigate(
                    location?.state?.previousUrl
                        ? location.state.previousUrl
                        : '/customers'
                );
              }
              else {
                setErrorMessage(msg=>"Invalid Credentials!")
                display(displayError=>true)
              }
          }).catch(e=>{
            display(true)
            setErrorMessage(msg=>"Connection to the server failed!")
          })
  }
  useEffect(()=>{
    setName(customer?customer.name:"")
    setEmail(customer?customer.email:"")
  },[customer])
  return (

    <form className="space-y-4 md:space-y-6" id="admin" onSubmit={updateCustomer}>
      {displayError && <ErrorComponent message={{e:errorMessage}}/>}
          <div>
            <Label labelFor={"name"} content={"Name"} />
            <Input id={"name"} type={"text"} value={name} handler={(e) => {setName(e.target.value)}} placeholder={"Customer Name"} />                 
          </div>
          <div>
              <Label labelFor={"email"} content={"Email"}  />
              <Input id={"email"} type={"text"} value={email} handler={(e) => { setEmail(e.target.value)}} placeholder={"name@company.com"}  />
          </div>
          <div>
            <Label labelFor={"password"} content={"Password"}  />
              <Input id={"password"} type={"password"} value={password} handler={(e) => { setPassword(e.target.value) }} placeholder={"Password"}  />
          </div>
          <div className="flex items-center justify-between">
          </div>
          <Button content={"Update Customer"} />
    </form>

  )
}

export default UpdateCustomer