import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Project from '../projects/project'
import Table from '../shared/table'

const ViewCustomer = () => {
    const {id} = useParams()

  const customer = useSelector(state=>state.global.customers).find(x=>Number(x.customerId)===Number(id))
  const projects = useSelector(state=>state.global.projects).filter(x=>Number(x.customerId)===Number(id))
  const navigate = useNavigate()
  const headerList = ['Project Name','Project Id','Customer Id','Category','Hardware','Action']
  
  return (
    <section className='flex flex-col items-center justify-center px-6 py-8 max-h-100'>

      
     
        <div key={1} className='flex flex-col items-center w-full'>
          <div className="flex items-center w-full sm:max-w-md px-4 py-4 my-10 bg-gray-700 rounded-lg shadow-md sm:w-11/12 overflow-auto">
            <div className="container ">
                <div className="flex justify-center w-full px-4 py-2 items-center">
                    <div className="text-xl justify-center font-bold uppercase text-gray-100">
                        Customer Details
                    </div>
                </div>
       
            <div className="flex flex-row items-center items-center mt-6 ">
            <div className='flex flex-row text-gray-100 basis-1/2 font-bold justify-center'>Customer Name</div>
            <div className='flex flex-row text-gray-100 basis-1/2 justify-center'>{customer ? customer.name: "Loading.."}</div>
        </div>
            <div className="flex flex-row items-center justify-center mt-6 overflow-x-auto">
                <div className='flex flex-row text-gray-100 basis-1/2 font-bold justify-center'><p>Customer ID</p></div>
                <div className='flex flex-row text-gray-100 basis-1/2 justify-center'>{customer? customer.customerId: "Loading.."}</div>
            </div>
            <div className="flex flex-row items-center justify-center mt-6 overflow-x-auto">
                <div className='flex flex-row text-gray-100 basis-1/2 font-bold justify-center'><p>Email</p></div>
                <div className='flex flex-row text-gray-100 basis-1/2 justify-center'>{customer? customer.email: "Loading.."}</div>
            </div>
  
            </div>
          </div>

        </div>
        {(()=>{
            let e = <Table heading={"Projects"} dataList={projects} component={(idx,project)=><Project key={idx} project={{projectData:project}} />} buttonContent={"Add Project"} handler={()=>navigate('/addproject')} headerList={headerList} />
            return projects && projects.length>0 ? e:<div className='text-gray-100 font-bold'> No Projects Yet..</div>
       
        })()
        }
        
    

    </section>
  )
}

export default ViewCustomer