import { baseUrl, deleteMethod } from "./shared";

export const fetchRequest = async (req) => {
    
    try{
        const url = baseUrl + req
        const token = localStorage.getItem("access")
        const headers = { 'Authorization': `Bearer ${token}` };
        const response = await fetch(url, { headers })
        const data = await response.json()
        
        return data;
    }catch(e){
        return e?.response
    }
    
}

export const apiRequest = async (req,method,body) => {

    try{
        const url = baseUrl + req
        const token = localStorage.getItem("access")
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        const response = await fetch(url, {
            method: method,
            headers: headers,
            body: body
        })
        const data = await response.json()
        return data;
    }catch(e){
        return e?.response
    }
    
}

export const deleteRequest = async(req) => {
    try{
        const url = baseUrl + req
        const token = localStorage.getItem("access")
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        const response = await fetch(url, { method:deleteMethod, headers:headers })
        return response

    }catch(e){
        return e
    }
}


export const fetchFile = async (req) => {
    
    try{
        const url = baseUrl + req
        const token = localStorage.getItem("access")
        const headers = { 'Authorization': `Bearer ${token}` }
        let response = await fetch(url, { headers })
        
        return response
    }catch(e){
        return e?.response
    }
    
}