import React from 'react'

const DataTable = ({measurandName,dataArray}) => {
  return (
    <div className="items-center w-full px-4 py-4 mx-auto my-10 bg-gray-700 rounded-lg shadow-md sm:w-11/12 max-h-screen overflow-auto no-scrollbar">
        <div className="container mx-auto ">
            <div className="flex justify-center w-full px-4 py-2 items-center ">
                <div className="text-xl justify-center font-bold uppercase text-gray-100 ">
                    {measurandName}
                </div>
            </div>
            <div className="mt-6 overflow-x-auto">
                <table className="flex flex-col w-full table-fixed ">
                    <thead className="flex ">
                        <tr className="flex w-full text-sm text-gray-100 font-semibold text-center uppercase ">
                          <th className="px-4 py-3 w-1/2">Rounded Timestamp</th>
                          <th className="px-4 py-3 w-1/2">Category</th>
                          <th className="px-4 py-3 w-1/2">Value</th>
                          <th className="px-4 py-3 w-1/2">Unit</th>
                        </tr>
                    </thead>
                    <tbody className="text-sm font-normal text-gray-100 text-center max-h-64 overflow-y-auto">

                    {
                        dataArray && 
                        dataArray.map((x,idx)=>{
                        return (
                        <tr key={idx} className="flex w-full bg-gray-700 hover:bg-gray-800 font-medium">
                            <td className="px-4 py-4 w-1/2 cursor-default">{x.roundedTimestamp}</td>
                            <td className="px-4 py-4 w-1/2 cursor-default">{x.measurandCategoryType}</td>
                            <td className="px-4 py-4 w-1/2 cursor-default">{x.value}</td>
                            <td className="px-4 py-4 w-1/2 cursor-default">{x.unit}</td>
                        </tr>)
                        })
                    }
                
                </tbody>
                </table>
            </div>
        </div>
    </div>
  )
}

export default DataTable