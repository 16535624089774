
import './App.css';
import { BrowserRouter,  Navigate,  Route, Routes } from 'react-router-dom';

import PrivateRoutes from './util/PrivateRoutes';
import Login from './components/login/login';
import Projects from './components/projects/projects';
import Customers from './components/customers/customers';
import Measurands from './components/measurands/measurands'
import ProjectCategories from './components/projectCategories/projectCategories';
import AddCustomer from './components/customers/addCustomer';
import UpdateCustomer from './components/customers/updateCustomer';
import NotFound from './components/notFound/notFound';
import UpdateAdmin from './components/admins/updateAdmin';
import AddAdmin from './components/admins/addAdmin';
import Admins from './components/admins/admins';
import AddMeasurand from './components/measurands/addMeasurand';
import UpdateMeasurand from './components/measurands/updateMeasurand';
import AddProjectCategory from './components/projectCategories/addProjectCategory';
import UpdateProjectCategory from './components/projectCategories/updateProjectCategory';
import AddProject from './components/projects/addProject';
import UpdateProject from './components/projects/updateProject';
import ViewProject from './components/projects/viewProject';
import Layout from './components/layout/layout';
import FormLayout from './components/layout/formLayout';
import ViewCustomer from './components/customers/viewCustomer';




function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        <Route element = {<Layout/>}>
          <Route element = {<PrivateRoutes />}>
            <Route element = {<FormLayout />} >
              <Route element = {<AddAdmin/>} path="/addadmin" exact/>
              <Route element = {<AddCustomer/>} path="/addcustomer" exact/>
              <Route element = {<AddMeasurand/>} path="/addmeasurand" exact/>
              <Route element = {<AddProject/>} path="/addproject" exact/>
              <Route element = {<AddProjectCategory/>} path="/addprojectcategory" exact/>
              <Route element = {<UpdateCustomer/>} path="/updatecustomer/:id" exact/>
              <Route element = {<UpdateAdmin/>} path="/updateadmin/:id" exact/>
              <Route element = {<UpdateProject/>} path="/updateproject/:id" exact/>
              <Route element = {<UpdateMeasurand/>} path="/updatemeasurand/:id" exact/>
              <Route element = {<UpdateProjectCategory/>} path="/updateprojectcategory/:id" exact/>
              <Route path="/" element={<Navigate replace to="/customers" />} exact/>
            </Route>
            <Route element = {<Admins/>} path="/admins" exact/>
            <Route element = {<Projects/>} path="/projects" exact/>
            <Route element = {<Customers/>} path="/customers" exact/>
            <Route element = {<Measurands/>} path="/measurands" exact/>
            <Route element = {<ProjectCategories/>} path="/projectcategories" exact/> 
            <Route element = {<ViewProject/>} path="/viewproject/:id" exact/>
            <Route element = {<ViewCustomer/>} path="/viewcustomer/:id" exact/>
        
          </Route>
        </Route>
        <Route element={<Login/>} path="/login"></Route>
        
        <Route element={<NotFound/>} path="*"></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
