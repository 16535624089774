import React, { useState } from 'react'
import { postMethod } from '../../util/shared';
import { useLocation, useNavigate } from 'react-router-dom';
import ErrorComponent from '../shared/error';
import Label from '../shared/label';
import Input from '../shared/input';
import Button from '../shared/button';
import { apiRequest } from '../../util/api';
import { fetchCustomers } from '../../util/stateChanges';
import { useDispatch } from 'react-redux';

function AddCustomer() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [displayError, display] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Unexpected Error!")
  const dispatch = useDispatch()
  const navigate = useNavigate();
    const location = useLocation()
    const body = JSON.stringify({
      name:name,
      email: email,
      password: password,
    })
    function addCustomer(e){
      e.preventDefault();
      apiRequest('createcustomer',postMethod,body).then((data) => {
          
              if(data.customerId !=null){
                fetchCustomers(dispatch)
                navigate(
                    location?.state?.previousUrl
                        ? location.state.previousUrl
                        : '/customers'
                );
              }
              else {
                setErrorMessage(msg=>"Invalid Credentials!")
                display(displayError=>true)
              }
          }).catch(e=>{
            display(displayError=>true)
            setErrorMessage(msg=>"Connection to the server failed!")
          }); 
    }
  return (
   
    <form className="space-y-4 md:space-y-6" id="admin" onSubmit={addCustomer}>
      {displayError && <ErrorComponent message={{e:errorMessage}}/>}
          <div>
            <Label labelFor={"name"} content={"Name"} />
            <Input id={"name"} type={"text"} value={name} handler={(e) => {setName(e.target.value)}} placeholder={"Customer Name"} />                 
          </div>
          <div>
              <Label labelFor={"email"} content={"Email"}  />
              <Input id={"email"} type={"text"} value={email} handler={(e) => { setEmail(e.target.value)}} placeholder={"name@company.com"}  />
          </div>
          <div>
            <Label labelFor={"password"} content={"Password"}  />
              <Input id={"password"} type={"password"} value={password} handler={(e) => { setPassword(e.target.value) }} placeholder={"Password"}  />
          </div>
          <div className="flex items-center justify-between">
          </div>
          <Button content={"Add Customer"} />
      
    </form>
         
  )
}

export default AddCustomer