import { createSlice} from "@reduxjs/toolkit";

const initialState = {
    isLoggedIn: false,
    adminId: 0,
    admins: [],
    measurands: [],
    projects: [],
    customers: [],
    measurandCategories: [{}],
    projectCategories: [{}],
    projectData: [{}],
    toast: {},
    modal:{},
    usedProjectCategories: [],
    usedMeasurands: []
    
};



export const globalSlice = createSlice({name: "global",initialState,reducers:{
    setLogout: (state) => {
        state.isLoggedIn = false;
    },
    setLogin: (state) => {
        state.isLoggedIn = true;
    },
    setAdmin: (state)=> {
        let adminId = localStorage.getItem("adminId")
        state.adminId =  adminId
    },
    setAdmins:(state,action) =>{
        state.admins = action.payload
    },
    setMeasurands: (state,action)=> {
        state.measurands = action.payload
    },
    setProjects: (state,action)=>{
        state.projects = action.payload
    },
    setMeasurandCategories: (state,action)=> {
        state.measurandCategories = action.payload
    },
    setProjectCategories: (state,action)=> {
        state.projectCategories = action.payload
    },
    setCustomers: (state,action)=>{
        state.customers = action.payload
    },
    setToast:(state,action)=>{
        state.toast = action.payload
    },
    setModal:(state,action)=>{
        state.modal = action.payload
    },
    setUsedProjectCategories:(state,action)=>{
        state.usedProjectCategories = action.payload
    },
    setUsedMeasurands:(state,action)=>{
        state.usedMeasurands = action.payload
    }
   
}})

export const { setLogout,setLogin,setAdmin,setMeasurands,setMeasurandCategories, setProjectCategories, setProjects, setCustomers,setAdmins,setToast, setModal, setUsedProjectCategories, setUsedMeasurands } = globalSlice.actions;

export default globalSlice.reducer;