import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAdmin, setLogin } from '../state';
import { parseJwt } from './jwtParse';



const PrivateRoutes = () => {
    let token = localStorage.getItem('access')
    const dispatch = useDispatch()
    let adminId = useSelector((state)=>state.global.adminId);
   
    dispatch(setAdmin(adminId))
    if (token!==null&& token!=="undefined"){

      let d = new Date(parseJwt(token).expired_at)
      if(d.getTime()>Date.now()){
        dispatch(setLogin())
      }
    }
    adminId = useSelector((state)=>state.global.adminId);
    let isLoggedIn = useSelector((state)=>state.global.isLoggedIn);
 

  return (
    
    isLoggedIn===true? <Outlet/>:<Navigate to="/login"/>
  )
}

export default PrivateRoutes