import { setUsedMeasurands, setUsedProjectCategories } from "../state";

export const generateUsedProductCategories = (projects,dispatch)=>{
    const set = new Set()
    try{
    if(projects!==null && projects.length!==0 && Array.isArray(projects)){
        projects?.forEach(x => {
            set.add(x.projectCategoryId)
        })
    }
    }catch(e){
        console.log(e)
    }
    
    dispatch(setUsedProjectCategories(Array.from(set)))
}

export const generateUsedMeasurands = (projectCategories,dispatch)=>{
    const set = new Set()
    if(projectCategories!==null && projectCategories.length!==0 && Array.isArray(projectCategories)){
        projectCategories?.forEach(x=>{
            x?.measurands?.forEach(y=>{
                set.add(y)
            })
        })
    }
    
    dispatch(setUsedMeasurands(Array.from(set)))
   
}