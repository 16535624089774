import React from 'react'
import { useNavigate } from 'react-router-dom'
import Measurand from '../measurands/measurand'
import { useSelector  } from 'react-redux'
import Table from '../shared/table'

const Measurands = () => {
  const navigate = useNavigate()
  const measurands = useSelector(state=>state.global.measurands)
  const headerList = ['Name','Measurand Id','Category','Action']
  return (
    <section className='flex flex-col items-center justify-center px-6 py-8 overflow-hidden'>
      <Table heading={"Measurands"} dataList={measurands} component={(idx,measurand)=><Measurand key={idx} measurand={{measurandData:measurand}} />} buttonContent={"Add Measurand"} handler={()=>navigate('/addmeasurand')} headerList={headerList} />
    </section>
  )
}

export default Measurands