import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import DataTable from '../shared/dataTable'
import { fetchProjectData } from '../../util/stateChanges'
import LineChart  from '../graph/lineChart'


const ViewProject = () => {
  const {id} = useParams()
  const [projectData,setProjectData] = useState([])
  const [dictionary,setDictionary] = useState({})
  const [filteredDictionary,setFilteredDictionary] = useState({})
  const [searchText, setSearchText] = useState("")
  const [loading,setLoading] = useState(false)
  const [showGraph,setShowGraph] = useState(false)

  function graphToggle(show){
    setShowGraph(show)
  } 

  function onSearchInput(val){
    setSearchText(val)
    let v = Object.fromEntries(Object.entries(dictionary).filter((([k,v])=>k.includes(val))))
    setFilteredDictionary(v)
  }

  function generateDictionary(data){
    var dict = {}

    data?.forEach(x=>{
      let a = []
      if(x.measurand in dict){
        a = dict[ x.measurand ]
      }
      a.push(x)
      dict[x.measurand] = a
    })
    setDictionary(dict)
    setFilteredDictionary(dict)
    
    
  } 
  
  useEffect(()=>{
      setLoading(true)
      fetchProjectData(id).then(data=>{
          generateDictionary(data.data.slice(-5000))
          setProjectData(data)
          setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

    
  return (
 

    <section className='flex flex-col items-center justify-center px-6 py-8 max-h-100'>
 
    {(()=>{
      
      let a = [];
        a.push(<div key={1} className='flex flex-col items-center w-full'>
          <div className="flex items-center w-full sm:max-w-md px-4 py-4 my-10 bg-gray-700 rounded-lg shadow-md sm:w-11/12 overflow-auto">
        <div className="container ">
            <div className="flex justify-center w-full px-4 py-2 items-center">
                <div className="text-xl justify-center font-bold uppercase text-gray-100">
                    Project Details
                </div>
            </div>
       
            <div className="flex flex-row items-center items-center mt-6 ">
            <div className='flex flex-row text-gray-100 basis-1/2 font-bold justify-center'>Project Name</div>
            <div className='flex flex-row text-gray-100 basis-1/2 justify-center'>{projectData.projectName}</div>
        </div>
        <div className="flex flex-row items-center justify-center mt-6 overflow-x-auto">
            <div className='flex flex-row text-gray-100 basis-1/2 font-bold justify-center'><p>Project Category</p></div>
            <div className='flex flex-row text-gray-100 basis-1/2 justify-center'>{projectData.projectCategoryName}</div>
        </div>
        <div className="flex flex-row items-center justify-center mt-6 overflow-x-auto">
            <div className='flex flex-row text-gray-100 basis-1/2 font-bold justify-center'><p>Project Id</p></div>
            <div className='flex flex-row text-gray-100 basis-1/2 justify-center'>{projectData.projectId}</div>
        </div>
            
           
        </div>
          </div>
          {
            Object.keys(filteredDictionary).length===0 &&
            <div className=' flex flex-col items-center items-center mt-6 font-bold text-gray-100 overflow-auto'>No Data to Display...</div>
          }
          <div className='w-full'>
          <div className='flex flex-row  px-12 items-center w-full px-4 py-4 mx-auto'>
          
          <div className=' w-1/4'>
          

         
        
        { 
        Object.keys(dictionary).length!==0 &&
      <div className="relative">
      
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
        </div>
        <input  id="default-search" value={searchText} onInput={e=>onSearchInput(e.target.value)} className="block w-full p-4 pl-10 text-sm border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white " placeholder="Search Measurands" required />
        
      </div>
        }
    
          </div>
          <div className='w-3/4'>
          </div>
          <div className='my-auto w-1/8'>
          <label className="relative inline-flex items-center cursor-pointer">
          <input onChange={(e)=>graphToggle(e.target.checked)} type="checkbox" value="" className="sr-only peer" />
          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" ></div>
          <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Graph</span>
          </label>
          </div>
          
          </div>
          
          {
            showGraph && <LineChart projectData={filteredDictionary} />
          }
          
          
          
          {(() => {
            let t = []
            let i=0 
            for (const [key, value] of Object.entries(filteredDictionary)) {
              t.push(<DataTable key={i} measurandName={key} dataArray={value} />)
              i++
            }
            return t
          })()
          }
    
          </div>
          </div>)
        
      return loading?<div className='flex flex-col text-white items-center justify-center  justify-center'>
      <div className="w-12 h-12 rounded-full animate-spin
                  border-4 border-solid border-gray-500 border-t-transparent">
          </div>
          </div>
    :a
    })()}
    </section>

  )
}

export default ViewProject