import React, { useState } from 'react'
import { baseUrl, postMethod } from '../../util/shared';
import { useLocation, useNavigate } from 'react-router-dom';
import ErrorComponent from '../shared/error';
import Tag from '../shared/tag';
import { useDispatch, useSelector } from 'react-redux';
import Label from '../shared/label';
import Input from '../shared/input';
import ListElement from '../shared/listElement';
import { fetchProjectCategories } from '../../util/stateChanges';
import { setToast } from '../../state';

const AddProjectCategory = () => {


  const token = localStorage.getItem("access")
  const [name, setName] = useState("");
  const [showMenu,setShowMenu] = useState(false)
  const [displayError, display] = useState(false);
  const [selectedMeasurandList,setSelectedMeasurandList] = useState([])
  const [measurandList,setMeasurandList] = useState(useSelector(state=>state.global.measurands))
  const [errorMessage, setErrorMessage] = useState("Unexpected Error!")
  
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
 
  function unSelectMeasurand(measurand){
    setSelectedMeasurandList(selectedMeasurandList.filter(x=>x.measurandId!==measurand.measurandId))
    let list = measurandList
    list.push(measurand)
    setMeasurandList(list)
  }

  function selectMeasurand(measurand) {
  
    setMeasurandList(measurandList.filter(x=>Number(x.measurandId)!==Number(measurand.measurandId)))
    let list = selectedMeasurandList
    list.push(measurand)
    setSelectedMeasurandList(list)
    toggleMenu()
  }
  function toggleMenu(){
    setShowMenu(!showMenu)
  }
    
    function addProjectCategory(e){
        e.preventDefault();
      
      const url = baseUrl + 'createprojectcategory';
      let list = [] 
      selectedMeasurandList.map(x=>list.push(x.measurandId))
      fetch(url, {
          
          method: postMethod,
          headers: {
            'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
          },
          
          body: JSON.stringify({
                projectCategoryName:name,
                measurands: list
          }),
      })
          .then((response) => {
              return response.json();
          })
          .then((data) => {
              if(data.projectCategoryId != null){
               fetchProjectCategories(dispatch)
               dispatch(setToast({message:"Project Category Successfully Created!",success:true}))
                navigate(
                    location?.state?.previousUrl
                        ? location.state.previousUrl
                        : '/projectcategories'
                );
              }
              else {
                setErrorMessage(msg=>"Unable to create project category")
                display(displayError=>true)
              }
              
              
          }).catch(e=>{
            display(displayError=>true)
            setErrorMessage(msg=>e)
          }); 
    }
  return (
    <form className="space-y-4 md:space-y-6" id="measurand" onSubmit={addProjectCategory}>
        {displayError && <ErrorComponent message={{e:errorMessage}}/>}
        <div>
          <Label labelFor={"name"} content={"Name"} />
          <Input id={"name"} type={"text"} value={name} handler={(e) => {setName(e.target.value)}} placeholder={"Project Category Name"} />
        </div>
     
        <div className="w-full sm:mb-2 relative">
 
        <span className="ml-2 text-sm text-gray-800 sm:text-base dark:text-gray-200">Measurands</span>
        <div onClick={toggleMenu} className="inline-flex mt-3 py-3 px-5 w-full border-2 border-purple-300 rounded outline-none placeholder:text-gray-400 invalid:text-pink-700 invalid:focus:ring-pink-700 invalid:focus:border-pink-700 
        peer dark:bg-gray-500 dark:text-gray-200 dark:placeholder:text-gray-300 dark:invalid:text-pink-300 dark:border-gray-400 cursor-pointer">Select Measurand</div>
     
        { showMenu && measurandList.length>0 && <div id="dropdown" className="bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-600 absolute z-[1] w-full">
            <div className="py-2 text-sm text-gray-700 dark:text-gray-200 max-h-32 overflow-y-auto">
              {measurandList && measurandList.map((mc,idx)=><ListElement name={mc.measurandName} element={mc} key={idx} handle={selectMeasurand}/>)}
            </div>
        
        </div>
        }
        
        </div>
       
        <div>
        <div className='px-2 pt-2 pb-11 mb-3 flex flex-wrap rounded-lg bg-purple-200 dark:bg-gray-400'>
          {selectedMeasurandList&&selectedMeasurandList.map((measurand,idx)=><Tag key={idx} handle={unSelectMeasurand} measurand={measurand} />)}
        </div>
      </div>
        <div className="flex items-center justify-between">
        </div>
        <button className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium text-gray-100 rounded px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Add project category</button>
    </form>
  )
  }

export default AddProjectCategory