import React from 'react'
import { useDispatch } from 'react-redux'
import { setToast } from '../../state'

const Toast = ({message,success}) => {
  const dispatch = useDispatch()
  const color = success?' bg-green-800':' bg-red-700';
  const styles = 'cursor-defualt flex fixed z-[100] items-center w-full max-w-xs p-4 mb-4 text-gray-500 rounded-lg shadow mt-16' + color
  function closeToast(){
    dispatch(setToast({}))
  }
  return (
    <div className={`${styles}`} role="alert">
    <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lgdark:text-green-200">
        <span className="sr-only">Check icon</span>
    </div>
    <div className="ml-3 mr-3 text-m font-normal text-gray-100">{message}.</div>
    <button type="button" onClick={closeToast} className="ml-auto -mx-1.5 -my-1.5  text-gray-100  rounded-lg focus:ring-2 hover:text-gray-900 focus:ring-gray-100 p-1.5 hover:bg-gray-100 hover:opacity-40 inline-flex h-8 w-8 ">
        <span className="sr-only">Close</span>
        <svg aria-hidden="true" className="w-5 h-5 opactity-100" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
    </button>
    </div>

  )
}

export default Toast