import React from 'react'
import Customer from './customer'
import { useNavigate } from 'react-router-dom'
import { fetchRequest } from '../../util/api'
import { setCustomers } from '../../state'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../shared/table'


const Customers = () => {
  const url = "listcustomers"
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const customers = useSelector(state=>state.global.customers)
  const headerList = ['customer Name','Email','Customer Id','Initial Login','Action']
  function updateList(){
    fetchRequest(url).then(data => dispatch(setCustomers(data)))
  }
  
  return (
    <section className='flex flex-col items-center justify-center px-6 py-8 overflow-hidden'>
      <Table heading={"Customers"} dataList={customers} component={(idx,customer)=><Customer key={idx} customer={{customerData:customer}} handler={updateList}/>} buttonContent={"Add Customer"} handler={()=>navigate('/addcustomer')} headerList={headerList} />
    </section>
  )
}

export default Customers