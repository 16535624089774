import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { postMethod } from '../../util/shared';
import ErrorComponent from '../shared/error';
import Label from '../shared/label';
import Input from '../shared/input';
import Button from '../shared/button';
import { fetchAdmins } from '../../util/stateChanges';
import { useDispatch } from 'react-redux';
import { apiRequest } from '../../util/api';

const AddAdmin = () => {
  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [displayError, display] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Unexpected Error!")
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch()
  function addadmin(e){
    e.preventDefault();
    const url = 'createadmin';
    const body= JSON.stringify({
      name:name,
      email: email,
      password: password,
    })
    apiRequest(url,postMethod,body).then((data) => {
            if(data.adminId != null){
             fetchAdmins(dispatch)
              navigate(
                  location?.state?.previousUrl
                      ? location.state.previousUrl
                      : '/admins'
              );
            }
            else {
              setErrorMessage(msg=>"Unable to create admin")
              display(displayError=>true)
            }
        }).catch(e=>{
          display(displayError=>true)
          setErrorMessage(msg=>e)
        }); 
  }
return (

  <form className="space-y-4 md:space-y-6" id="admin" onSubmit={addadmin}>
  {displayError && <ErrorComponent message={{e:errorMessage}}/>}
      <div>
        <Label labelFor={"name"} content={"Name"} />
        <Input id={"name"} type={"text"} value={name} handler={(e) => {setName(e.target.value)}} placeholder={"Admin Name"} />                 
         
      </div>
      <div>
          <Label labelFor={"email"} content={"Email"}  />
          <Input id={"email"} type={"text"} value={email} handler={(e) => { setEmail(e.target.value)}} placeholder={"name@company.com"}  />
      </div>
      
      <div>
        <Label labelFor={"password"} content={"Password"}  />
          <Input id={"password"} type={"password"} value={password} handler={(e) => { setPassword(e.target.value) }} placeholder={"Password"}  />
      </div>
      <div className="flex items-center justify-between">
         
      </div>
      <Button content={"Add Admin"} />
      
  </form>

  )
}
export default AddAdmin