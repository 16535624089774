import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { putMethod } from '../../util/shared';
import ListElement from '../shared/listElement';
import ErrorComponent from '../shared/error';
import Tag from '../shared/tag';
import { fetchProjectCategories } from '../../util/stateChanges';
import Label from '../shared/label';
import Input from '../shared/input';
import Button from '../shared/button';
import { apiRequest } from '../../util/api';

const UpdateProjectCategory = () => {
    const {id} = useParams()
    const measurands = useSelector(state=>state.global.measurands)
    const projectCategory = useSelector(state=>state.global.projectCategories).find(x=>Number(x.projectCategoryId)===Number(id))
    const [name, setName] = useState("");
    const [showMenu,setShowMenu] = useState(false)
    const [displayError, display] = useState(false);
    const [selectedMeasurandList,setSelectedMeasurandList] = useState([])
    const [measurandList,setMeasurandList] = useState(measurands)
    const [errorMessage, setErrorMessage] = useState("Unexpected Error!")
    
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
   
    function unSelectMeasurand(measurand){
      setSelectedMeasurandList(selectedMeasurandList.filter(x=>x.measurandId!==measurand.measurandId))
      let list = measurandList
      list.push(measurand)
      setMeasurandList(list)
    }

    function selectMeasurand(measurand) {
      setMeasurandList(measurandList.filter(x=>Number(x.measurandId)!==Number(measurand.measurandId)))
      let list = selectedMeasurandList
      list.push(measurand)
      setSelectedMeasurandList(list)
      toggleMenu()
    }
    function toggleMenu(){
      setShowMenu(!showMenu)
    }
    
    
    function updateProjectCategory(e){
      
      e.preventDefault();
      const url = 'updateprojectcategory/'+id;
      let list = [] 
      selectedMeasurandList.map(x=>list.push(x.measurandId))
      const body = JSON.stringify({
        projectCategoryName:name,
        measurands: list
      })

      apiRequest(url,putMethod,body)
          .then((data) => {
              if(data.projectCategoryId != null){
               fetchProjectCategories(dispatch)
                navigate(
                    location?.state?.previousUrl
                        ? location.state.previousUrl
                        : '/projectcategories'
                );
              }
              
              else if(data.error!==null) {
                if(data.error==="token expired")
                {
                  navigate('/login')
                }
                setErrorMessage("Unable to create project category")
                display(true)
              }

              
              
              
          }).catch(e=>{
            display(true)
            setErrorMessage(e)
          }); 
    }

    useEffect(()=>{
     
      setName(projectCategory?projectCategory.projectCategoryName:"")
      let sml = measurands?measurands.filter(x=>projectCategory.measurands?.find(y=>Number(y)===Number(x.measurandId))):[]
      setSelectedMeasurandList(sml)
      let ml = measurands?measurands.filter(x=>!sml.find(y=>Number(y.measurandId)===Number(x.measurandId))):[]
      setMeasurandList(ml)
    },[measurands,projectCategory])
  return (

    <form className="space-y-4 md:space-y-6" id="measurand" onSubmit={updateProjectCategory}>
    {displayError && <ErrorComponent message={{e:errorMessage}}/>}
        <div>
          <Label labelFor={"name"} content={"Name"} />
          <Input id={"name"} type={"text"} value={name} handler={(e) => {setName(e.target.value)}} placeholder={"Project Category Name"} />
        </div>
     
        <div className="w-full sm:mb-2 relative">
 
        <span className="ml-2 text-sm text-gray-800 sm:text-base dark:text-gray-200">Measurands</span>
        <div onClick={toggleMenu} className="inline-flex mt-3 py-3 px-5 w-full border-2 border-purple-300 rounded outline-none placeholder:text-gray-400 invalid:text-pink-700 invalid:focus:ring-pink-700 invalid:focus:border-pink-700 
        peer dark:bg-gray-500 dark:text-gray-200 dark:placeholder:text-gray-300 dark:invalid:text-pink-300 dark:border-gray-400">Select Measurand</div>
     
        { showMenu && measurandList.length>0 && <div id="dropdown" className="bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-600 absolute z-[1] w-full">
            <div className="py-2 text-sm text-gray-700 dark:text-gray-200 max-h-32 overflow-y-auto">
              {measurandList && measurandList.map((mc,idx)=><ListElement name={mc.measurandName} element={mc} key={idx} handle={selectMeasurand}/>)}
            </div>
        
        </div>
        }
        
        </div>
       
        <div>
        <div className='px-2 pt-2 pb-11 mb-3 flex flex-wrap rounded-lg bg-purple-200 dark:bg-gray-400'>
          {selectedMeasurandList&&selectedMeasurandList.map((measurand,idx)=><Tag key={idx} handle={unSelectMeasurand} measurand={measurand} />)}
        </div>
      </div>
        <div className="flex items-center justify-between">
        </div>
        <Button content={"Update Project Category"} />
   
    </form>
  )
}

export default UpdateProjectCategory