import React, { useEffect, useState } from 'react'
import ErrorComponent from '../shared/error';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { baseUrl, putMethod } from '../../util/shared';
import { useDispatch, useSelector } from 'react-redux';
import Label from '../shared/label';
import Input from '../shared/input';
import { fetchMeasurandCategories, fetchMeasurands } from '../../util/stateChanges';
import ListElement from '../shared/listElement';
import Dropdown from '../shared/dropdown';
import Button from '../shared/button';

const UpdateMeasurand = () => {
  const {id} = useParams()
  const token = localStorage.getItem("access")
  const measurandCategories = useSelector(state=>state.global.measurandCategories)
  const measurand = useSelector(state=>state.global.measurands).find(x=>Number(x.measurandId)===Number(id))
  const dropDownList = [...measurandCategories,{measurandCategoryName:"Type your Own"}]
  const dispatch = useDispatch()
  const [measurandCategory, setMeasurandCategory] = useState("")
  const [name, setName] = useState("");
  const [unit,setUnit] = useState("");
  const [displayError, display] = useState(false);
  const [activeElementType,setActiveElementType] = useState("dropdown")
  const [errorMessage, setErrorMessage] = useState("Unexpected Error!")
  const [showMeasurands, setShowMeasurands] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  function toggleSelectMeasurand(){
    setShowMeasurands(!showMeasurands)
  }

  useEffect(()=>{
   
    setName(measurand?measurand.measurandName:"")
    setUnit(measurand?measurand.unit:"")
    setMeasurandCategory(measurand?measurand.measurandCategoryType:"Select Measurand category")
   
  },[measurand])
  
  function dropDownChanged(mc) {
    toggleSelectMeasurand()
    

    setMeasurandCategory(mc.measurandCategoryName)
    if (mc.measurandCategoryName === "Type your Own") {
      setActiveElementType({ activeElementType: "input" });
      setMeasurandCategory("")
    }
  }
 
  
  function updateMeasurand(e){
      e.preventDefault();
    if(measurandCategory==null){
      setErrorMessage("Please Select A Valid Measurand Category!")
      display(displayError=>true)
      return
    }
    const url = baseUrl + 'updatemeasurand/'+id;
    fetch(url, {
        method: putMethod,
        headers: {
          'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        
        body: JSON.stringify({
              measurandName:name,
              unit: unit,
              measurandCategoryType: measurandCategory
        }),
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if(data.measurandId != null){
              fetchMeasurands(dispatch)
              fetchMeasurandCategories(dispatch)
              navigate(
                  location?.state?.previousUrl
                      ? location.state.previousUrl
                      : '/measurands'
              );
            }
            else {
              setErrorMessage(msg=>"Unable to create measurand")
              display(displayError=>true)
            }
            
            
        }).catch(e=>{
          display(displayError=>true)
          setErrorMessage(msg=>e)
        }); 
  }
  return (
    <form className="space-y-4 md:space-y-6" id="measurand" onSubmit={updateMeasurand}>
    {displayError && <ErrorComponent message={{e:errorMessage}}/>}
        <div>
            <Label content={"Measurand Name"} labelFor={"name"} />
            <Input id={"name"} handler={(e) => {setName(e.target.value)}} placeholder={"Measurand Name"} value={name} type={"text"} />
        </div>
        <div>
            <Label content={"Unit"} labelFor={"unit"} />
            <Input id={"unit"} handler={(e) => {setUnit(e.target.value)}} placeholder={"Measurand Unit"} value={unit} type={"text"} />
        </div>
        <div>
        <span className="ml-2 text-sm text-gray-800 sm:text-base dark:text-gray-200">Measurand Category</span>
          { activeElementType==="dropdown" || measurandCategories.length===0?
          <div className='relative w-full'>
          <Dropdown content={measurandCategory} handle={toggleSelectMeasurand} />
          { 
            showMeasurands && measurandCategories && measurandCategories.length > 0 && 
            <div id="dropdown" className="bg-white divide-y divide-gray-100 shadow w-44 dark:bg-gray-600 absolute z-[1] w-full overflow-y-scroll max-h-32">
            <div className="py-2 text-sm text-gray-700 dark:text-gray-200">
            { dropDownList.map((mc,idx)=><ListElement name={mc.measurandCategoryName} element={mc} key={idx} handle={dropDownChanged} />)}
            </div>
            </div>
          }
          </div>
          
          :
          <Input id={"measurandCategory"} value={measurandCategory} type={"text"} handler={e => {setMeasurandCategory(e.target.value)}} placeholder={"Type Custom Measurand Category"} />
          }
        </div> 
        <div className="flex items-center justify-between">
           
        </div>
        
        <Button content={"Update Measurand"} />
    </form>
  )
}

export default UpdateMeasurand