import { setAdmins, setCustomers, setMeasurandCategories, setMeasurands, setProjectCategories, setProjects } from "../state"
import { fetchRequest, fetchFile } from "./api"

export const fetchAdmins = (dispatch)=>{
    fetchRequest("listadmins").then(data=>dispatch(setAdmins(data)))
}

export const fetchProjects = (dispatch)=>{
    fetchRequest("listprojects").then(data=>dispatch(setProjects(data)))
}

export const fetchProjectCategories = (dispatch)=>{
    fetchRequest("listprojectcategories").then(data=>dispatch(setProjectCategories(data)))
}

export const fetchCustomers = (dispatch)=>{
    fetchRequest("listcustomers").then(data => dispatch(setCustomers(data)))
}

export const fetchMeasurands = (dispatch)=>{
    fetchRequest("listmeasurands").then(data=>dispatch(setMeasurands(data)))
}

export const fetchMeasurandCategories = (dispatch)=>{
    fetchRequest("listmeasurandcategories").then(data=>{
        dispatch(setMeasurandCategories(data))})
}

export const fetchProjectData = (id)=>{
    let limit = 1000

    const queryParams = new URLSearchParams({
        id: id,
        limit: limit
    });

    const u = `getprojectdata?${queryParams}`
    return fetchRequest(u)
}

export const fetchProjectDataFile = (id)=>{
    const u = "getprojectdatafile/"+id
    return fetchFile(u)
}
