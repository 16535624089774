import React from 'react'
import { ResponsiveLine } from '@nivo/line'
import { useEffect, useState } from 'react'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const LineChart = ({projectData}) => {
    const [lcd,setLcd] = useState([])
    const [filterData, setFilterData] = useState([])
    const [toggleDropdown,setToggleDropdown] = useState(false)
    const [currentFilter,setCurrentFilter] = useState(0)
    function setFilters(e,idx){
        e.preventDefault()
        setCurrentFilter(idx)
        filter(idx)
        setToggleDropdown(false)
    }
    
    function filterByHours(val){
        let currentDate = new Date(Date.now()) 
        let filterTimestamp = currentDate.setHours(currentDate.getHours()-val)
        
        let fd = []
        lcd.forEach(x=>{

            let a = []
            x.data.forEach(d=>{
                let dd = new Date(d.x)
    
                if(filterTimestamp<dd){
     
                    a.push({"x":dd.getHours()+":"+dd.getMinutes(),"y":d.y})
                }
            })
            fd.push({"id":x.id,data:a})
        })

        setFilterData(fd)
    }
    function filterByDays(val){
        let currentDate = new Date(Date.now()) 
        let filterTimestamp = currentDate.setDate(currentDate.getDate()-val)
        let fd = []
     
        lcd.forEach(x=>{

            let a = []
            x.data.forEach(d=>{
    
                let dd = new Date(d.x)
    
                if(filterTimestamp<dd){
                    
                    a.push({"x":dd.getDate()+"-"+ dd.getHours(),"y":d.y})
                }
            })
            fd.push({"id":x.id,data:a})
        })

        setFilterData(fd)
    }
    const filterVals = ['1 Hour','12 Hours','1 Day','7 Days','1 Month']
    function filter(idx){
        switch(idx){
            case 0: filterByHours(1); break
            case 1: filterByHours(12); break
            case 2: filterByDays(1); break
            case 3: filterByDays(7); break
            case 4: filterByDays(30); break
            default:
        }
    }

    useEffect(()=>{
        if(projectData!==null){
     
            let dd = []
            for (const [key, value] of Object.entries(projectData)) {
                let a = []
                value.forEach(x=>{
                    let date = new Date(x.timestamp)
                    a.push({"x":date,"y":x.value})
                })
                dd.push({"id":key,"data":a})
              }
            setLcd(dd)
            filter(0)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[projectData])
  
   
    return(
        <div className='h-128 items-center w-full px-4 py-10 mx-auto my-10 bg-gray-800 rounded-lg shadow-md sm:w-11/12 max-h-screen'>
        <div>

<button onClick={e=>setToggleDropdown(!toggleDropdown)} id="dropdownDefaultButton" data-dropdown-toggle="dropdown" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">{filterVals[currentFilter]}<svg className="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path></svg></button>
{ toggleDropdown && <div id="dropdown" className="absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-32 dark:bg-gray-700">
    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
    { 
        filterVals.map((x,idx)=>{
            return <li key={idx} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white hover:cursor-pointer" >
            <p onClick={e=>setFilters(e,idx)}>{x}</p>
          </li>
        })
    }

      
    </ul>
</div>
}
        </div>

        <ResponsiveLine
        data={filterData}
        theme={{
            axis:{
                legend:{
                    text:{
                        fill:"rgb(209 213 219)"
                    }
                },

               ticks:{
                text:{
                    fill: 'rgb(209 213 219)'
                }
               }     
            },
            legends:{
                text:{
                    fill: 'rgb(209 213 219)'
                }
            },
            tooltip: {
                container: {
                  color: 'rgb(209 213 219)',
                  background: 'transparent'
                },
              },
           
        }}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 90,
            legend: '',
            legendOffset: 36,
            legendPosition: 'middle'
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Value',
            legendOffset: -40,
            legendPosition: 'middle'
        }}
        enableGridX={false}
        enableGridY={false}
        colors={{ scheme: 'category10' }}
        pointSize={4}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
            {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
    />

        </div>
        
    )
}

export default LineChart