import React from 'react'
import { Outlet } from 'react-router-dom'

const FormLayout = () => {
   let loc = window.location.href.split("/")[3]
   
   let idx = loc.includes("update")?6:3;
   let sp = [loc.slice(0, idx), loc.slice(idx)]
   let first = sp[0]
   let second = sp[1]

  return (
    <div className="flex flex-col items-center justify-center px-6 py-8  h-100 mt-10 ">
    <a href="http://folivoraenergy.com" className="flex items-center mb-6 text-2xl font-semibold uppercase text-gray-900 dark:text-white" >
      {first}  {second}
    </a>
       
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-700 dark:border-gray-700">
        
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                    Enter details 
                </h1>
                <Outlet />
            </div>
        </div>
    </div>
  )
}

export default FormLayout