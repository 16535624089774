import React from 'react'
import Button from './button'

const Table = ({heading,dataList,component,headerList, handler, buttonContent}) => {
  return (
    <div className="items-center w-full px-4 py-4 mx-auto my-10 bg-gray-700 rounded-lg shadow-md sm:w-11/12 max-h-screen overflow-auto no-scrollbar">
    <div className="container mx-auto">
    <div className="flex justify-between w-full px-4 py-2 items-center">
    <div className="text-xl font-bold uppercase text-gray-100 ">
        {heading}
    </div>
    <div className="px-4 py-2">
    <Button handle={handler} content={buttonContent} />
    </div>
    
</div>
    <div className="mt-6 overflow-x-auto w-full">
    <table className="flex flex-col w-full table-fixed ">
        <thead className="flex ">
            <tr className="flex w-full text-sm text-gray-100 font-semibold text-center uppercase">
            {
                headerList && headerList.length>0 && headerList.map((headerName,idx)=><th key={idx} className="px-4 py-3 w-1/2">{headerName}</th>)
            }

            </tr>
        </thead>
        <tbody className="flex flex-col w-full text-sm font-normal text-gray-100 text-center overflow-y-auto max-h-128">

        { 
          dataList && dataList.length > 0 && dataList.map((project,idx)=>
            
            component(idx, project)
          )
        }
    
    </tbody>
    </table>
</div>
 </div>
 </div>      
  )
}

export default Table