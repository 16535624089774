import React from 'react'

const ListElement = ({element,handle,name}) => {
  function handleClick(){
    handle(element)
  }
  return (
    <div onClick={handleClick} tabIndex="-1" className="hover:cursor-pointer px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white" >{name}</div>
  )
}

export default ListElement