import React from 'react'

const Input = ({handler,value,placeholder,type, id}) => {
  return (
        <input id={id}
        className="inline-flex mt-3 py-3 px-5 w-full border-2 border-purple-300 rounded outline-none placeholder:text-gray-400 
        peer dark:bg-gray-500 dark:text-gray-200 dark:placeholder:text-gray-300 dark:border-gray-400"
        type={type} min="1"
        value={value} 
        placeholder={placeholder}
        onChange={e=>handler(e)}  required={true} autoComplete='on'/> 
  )
}

export default Input