import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { putMethod } from '../../util/shared';
import ErrorComponent from '../shared/error';
import Label from '../shared/label';
import Input from '../shared/input';
import { fetchAdmins } from '../../util/stateChanges';
import { useDispatch, useSelector } from 'react-redux';
import { apiRequest } from '../../util/api';

const UpdateAdmin = () => {
  const {id} = useParams()
  const [email, setEmail] = useState("Loading..");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("Loading..");
  const [displayError, display] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Unexpected Error!")
  const admin = useSelector(state=>state.global.admins).find(x=>Number(x.adminId)===Number(id))
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const location = useLocation();
  useEffect(()=>{
    setName(admin?admin.name:"")
    setEmail(admin?admin.email:"")
  },[admin])
  function updateadmin(e){
      e.preventDefault();
    const url = 'updateadmin/'+id;
    const body = JSON.stringify({
      name:name,
      email: email,
      password: password,
    })
    
    apiRequest(url,putMethod,body)
        .then((data) => {
            if(data.adminId !== null){
              fetchAdmins(dispatch)
              navigate(
                  location?.state?.previousUrl
                      ? location.state.previousUrl
                      : '/admins'
              );
            }
            else {
              setErrorMessage(msg=>"Invalid Credentials!")
              display(displayError=>true)
            }
            
            
        }).catch(e=>{
          display(displayError=>true)
          setErrorMessage(msg=>"Connection to the server failed!")
        }); 
  }
return (
    <form className="space-y-4 md:space-y-6" id="admin" onSubmit={updateadmin}>
    {displayError && <ErrorComponent message={{e:errorMessage}}/>}
        <div>
          <Label labelFor={"name"} content={"Name"} />
          <Input id={"name"} type={"text"} value={name} handler={(e) => {setName(e.target.value)}} placeholder={"Admin Name"} />                 
        </div>
        <div>
            <Label labelFor={"email"} content={"Email"}  />
            <Input id={"email"} type={"text"} value={email} handler={(e) => { setEmail(e.target.value)}} placeholder={"name@company.com"}  />
        </div>
        <div>
          <Label labelFor={"password"} content={"Password"}  />
            <Input id={"password"} type={"password"} value={password} handler={(e) => { setPassword(e.target.value) }} placeholder={"Password"}  />
        </div>
        <div className="flex items-center justify-between">           
        </div>
        <button className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Update admin</button>
        
    </form>
)
}

export default UpdateAdmin